import {Card, Col, ProgressBar, Row} from "react-bootstrap";
import {colours} from "../data/colors";
import {useEffect, useState} from "react";

export function PokemonData(props) {
    const [cardStyle, setCardStyle] = useState('')

    useEffect(() => {
        setCardStyle('light');
        setTimeout(() => {
            setCardStyle('');
        }, 750);
    }, [setCardStyle])

    return (<Card className={"mt-3"} bg={cardStyle}>
        <Row>
            <Col xs={12} md={3}>
                <img src={props.sprite} alt={props.name} width="100%"/>
            </Col>
            <Col xs={12} md={5} className={"mt-1"}>
                <h1>{props.name.charAt(0).toUpperCase() + props.name.slice(1)}</h1>
                <h5>#{props.id}</h5>
                {props.types.map((type, id) => (<span className="p-1 me-2"
                                                      style={{
                                                          background: colours[type.type.name],
                                                          borderRadius: "5px",
                                                          fontWeight: '700'
                                                      }}
                                                      key={id}>
                            {type.type.name.toUpperCase()}</span>))}
                <p className={"mt-1"}>{props.desc.replace("/n", " ")}</p>
            </Col>
            <Col xs={12} md={4} className={"mt-1"}>
                <h4>Base Stats</h4>
                {props.stats.map((stat, key) => (
                    <div key={key} className={"me-4"}>
                        <strong>{stat.stat.name.toUpperCase().replace("-"," ")}</strong>
                        <ProgressBar now={stat.base_stat} max={255} label={stat.base_stat}/>
                    </div>
                ))}
            </Col>
        </Row>
    </Card>)
}