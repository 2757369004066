import {Alert, Button, Col, Form, Row, Spinner} from "react-bootstrap";
import {useState} from "react";
import {useTrainersContext} from "../contexts/TrainersContext";

export function SearchForTrainer(props) {
    const {trainer} = props;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("")
    const [search, setSearch] = useState("");
    const [hasSearched, setHasSearched] = useState(false);
    const {addPokemon} = useTrainersContext();
    const getPokemon = async (query) => {
        if (!query) {
            setMessage("Please enter a Pokémon (by name or number).")
            setError(true);
            return;
        }
        setError(false);
        setLoading(true);
        setTimeout(async () => {
            try {
                setHasSearched(true);
                const response = await fetch(`https://pokeapi.co/api/v2/pokemon/${query}`);
                const results = await response.json();
                const secondResponse = await fetch(`https://pokeapi.co/api/v2/pokemon-species/${query}`);
                //console.log(secondResponse);
                const secondResults = await secondResponse.json();
                //console.log(secondResults);
                const mergedResults = {...results, ...secondResults};
                setLoading(false);
                {setMessage(`Pokémon ${mergedResults.name} added!`)}
                addPokemon(trainer,mergedResults);
            } catch (error) {
                console.log(error);
                setLoading(false);
                setError(true);
                setMessage("This Pokémon was not found. Try again.");
            }
        }, 1500);
    }

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            event.preventDefault();
            getPokemon(search).then( () => console.log("Promise"));
        }
    }

    return (
        <>
            <Form className="mt-2">
                <Row>
                    <Col sm={9} className="my-1">
                        <Form.Control
                            onChange={(e) => setSearch(e.target.value.toLowerCase())}
                            onKeyDown={handleKeyDown}
                            placeholder="Search a Pokemon"></Form.Control>
                    </Col>
                    <Col sm={3} className="my-1">
                        <div className="d-grid gap-2">
                            <Button onClick={() => getPokemon(search)}>Search</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
            {!loading && hasSearched? <div className="my-1">{error ? <Alert variant="warning">
                {message}
            </Alert> : <Alert variant="success">{message}</Alert>}
            </div> : null}
            {loading ? (
                <div style={{textAlign: 'center'}} className={'mt-3'}>
                    <Spinner animation="border"/>
                </div>
            ) : null}
        </>
    )
}