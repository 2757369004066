import {useState} from 'react';
import Collapse from 'react-bootstrap/Collapse';
import {Card, Container} from "react-bootstrap";
import {AllPokemon} from "../components/AllPokemon";
import {RandomPokemon} from "../components/RandomPokemon";
import {GenerationsPokemon} from "../components/GenerationsPokemon";
import {TypesPokemon} from "../components/TypesPokemon";

export function StartersPage(props) {
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);

    const {pokemons} = props;

    return (
        <>
            <Container>
                <Card className="my-2" onClick={() => setOpen(!open)}>
                    <Card.Header>All starter Pokémon</Card.Header>
                </Card>
                <Collapse in={open}>
                    <div>
                        <AllPokemon pokemons={pokemons}/>
                    </div>
                </Collapse>
            </Container>
            <Container>
                <Card className="my-2" onClick={() => setOpen2(!open2)}>
                    <Card.Header>Random starter Pokémon</Card.Header>
                </Card>
                <Collapse in={open2}>
                    <div>
                        <RandomPokemon pokemons={pokemons}/>
                    </div>
                </Collapse>
            </Container>
            <Container>
                <Card className="my-2" onClick={() => setOpen3(!open3)}>
                    <Card.Header>Starter Pokémon by Generation</Card.Header>
                </Card>
                <Collapse in={open3}>
                    <div>
                        <GenerationsPokemon pokemons={pokemons}/>
                    </div>
                </Collapse>
            </Container>
            <Container>
                <Card className="my-2" onClick={() => setOpen4(!open4)}>
                    <Card.Header>Starter Pokémon by type</Card.Header>
                </Card>
                <Collapse in={open4}>
                    <div>
                        <TypesPokemon pokemons={pokemons}/>
                    </div>
                </Collapse>
            </Container>
        </>
    );
}