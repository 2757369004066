import 'normalize.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-tabs/style/react-tabs.css';
import './App.css';
import {POKEMON} from "./data/data";
import {Tabs, TabList, Tab, TabPanel} from "react-tabs";
import {TrainerPage} from "./pages/TrainerPage";
import {SearchPage} from "./pages/SearchPage";
import {StartersPage} from "./pages/StartersPage";
import {TrainersProvider} from "./contexts/TrainersContext";


function ProvidedApp() {
    return (
        <Tabs defaultIndex={2}>
            <TabList>
                <Tab>Starter Pokémon</Tab>
                <Tab>Search</Tab>
                <Tab>Trainer</Tab>
            </TabList>
            <TabPanel>
                <StartersPage pokemons={POKEMON}/>
            </TabPanel>
            <TabPanel>
                <SearchPage/>
            </TabPanel>
            <TabPanel>
                <TrainerPage/>
            </TabPanel>
        </Tabs>
    );
}

function App() {
    return (
            <TrainersProvider>
                <ProvidedApp/>
            </TrainersProvider>
    );
}

export default App;

