import {useState} from "react";
import {Button, Container} from "react-bootstrap";
import {AllPokemon} from "../components/AllPokemon";

export function TypesPokemon(props) {
    const {pokemons} = props;
    const [grass, setGrass] = useState(true);
    const [fire, setFire] = useState(true);
    const [water, setWater] = useState(true);

    return (
            <Container>
                <Button className="ms-2 mt-1" variant={grass? "primary": "secondary"} onClick={() => setGrass(!grass)}>Grass</Button>
                <Button className="ms-2 mt-1" variant={fire? "primary": "secondary"} onClick={() => setFire(!fire)}>Fire</Button>
                <Button className="ms-2 mt-1" variant={water? "primary": "secondary"} onClick={() => setWater(!water)}>Water</Button>
                <>
                    {grass && <AllPokemon pokemons={pokemons.filter(p => p.type === "Grass")}/>}
                    {fire && <AllPokemon pokemons={pokemons.filter(p => p.type === "Fire")}/>}
                    {water && <AllPokemon pokemons={pokemons.filter(p => p.type === "Water")}/>}
                </>
            </Container>
    )
}