import * as PropTypes from "prop-types";
import {Button, Card, Col, Row} from "react-bootstrap";
import {v4 as uuidv4} from 'uuid';

function Trainer(props) {
    const {trainer, onEditTrainer, onDeleteTrainer} = props;
    return (
        <Card className="mb-2">
            <h5 className="card-title">{trainer.name}</h5>
            <h6 className="card-subtitle mb-2 text-muted">{trainer.city}</h6>
            <Row>{trainer.party.map(p =>
                <Col xs={4} lg={2} key={uuidv4()}>
                    <Card className={`m-2 p-2 shadow-sm text-center`}>
                        <img src={`https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/${p}.png`}
                             alt={`Pokemon with id ${p}`}
                        />
                    </Card>
                </Col>
            )}
            </Row>
            <Row>
                <Col>
                    <Button className="m-2" variant="warning" onClick={() => onEditTrainer(trainer)}>EDIT</Button>
                    <Button className="m-2" variant="danger" onClick={() => onDeleteTrainer(trainer)}>DELETE</Button>
                </Col>
            </Row>
        </Card>
    );
}

Trainer.propTypes = {
    trainer: PropTypes.shape({
        name: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        party: PropTypes.array
    })
}

export function Trainers(props) {
    const {trainers, onEditTrainer, onDeleteTrainer} = props;
    return (
        <Row>
            {trainers?.map(t =>
                <Trainer key={t.id}
                         trainer={t}
                         onEditTrainer={onEditTrainer}
                         onDeleteTrainer={onDeleteTrainer}
                />)}
        </Row>
    );
}

Trainers.propTypes = {
    persons: PropTypes.array
}