import React, {createContext, useCallback, useContext, useMemo, useState} from "react";
import {addDoc, collection, updateDoc, deleteDoc, arrayRemove, arrayUnion} from "firebase/firestore";
import {firestoreDB} from "../services/firebase";
import {useCollectionData} from "react-firebase-hooks/firestore";


const TrainersContext = createContext();

const trainerConverter = {
    toFirestore: function (dataInApp) {
        return {
            name: dataInApp.name,
            city: dataInApp.city,
            party: dataInApp.party,
        };
    },
    fromFirestore: function (snapshot, options) {
        const data = snapshot.data(options);
        return {...data, id: snapshot.id, ref: snapshot.ref}
    }
};

export function TrainersProvider(props) {
    const [trainerSelected, setTrainerSelected] = useState();

    const collectionRef = useMemo(() =>
        collection(firestoreDB, 'trainers').withConverter(trainerConverter), []);
    const [trainers, loading, error] = useCollectionData(collectionRef);

    console.log({trainers, loading, error});

    const editTrainer = useCallback((trainer) => {
        setTrainerSelected(trainer)
    }, []);

    const editTrainerSave = useCallback(async (trainer) => {
        await updateDoc(trainer.ref, trainer);
        console.log("Trainer was saved.");
        return true;
    }, [])

    const addNewTrainer = useCallback(async () => {
            const randomParty = [...Array(5)].map(() => ~~(Math.random() * 905));
            const newTrainer = {name: "Trainer", city: "Pewter City", party: randomParty};
            try {
                await addDoc(collectionRef, newTrainer);
                console.log("New trainer with random party was generated.");
            } catch {
                console.log("!!! FAILED to add new person");
            }
        }, [collectionRef]
    );


    const deleteTrainer = useCallback(async (trainer) => {
        try {
            await deleteDoc(trainer.ref);
            console.log(`${trainer.name} is deleted`)
        } catch {
            console.log(`!!! FAILED to delete ${trainer.name}`)
        }
    }, []);

    const addPokemon = useCallback(async (trainer, pokemon) => {
        try {
            await updateDoc(trainer.ref, {
                party: arrayUnion(pokemon.id)
            });
            console.log(`Pokémon ${pokemon.name} added!`)
        } catch (e) {
            console.log("!!! FAILED to add Pokémon to party.")
            console.log(e)
        }
    }, []);

    const deletePokemon = useCallback(async (trainer, pokemon) => {
        console.log(pokemon)
        try {
            console.log(trainer.party)
            await updateDoc(trainer.ref, {
                party: arrayRemove(pokemon)
            });
            console.log("Pokémon removed from party.")
        } catch (e) {
            console.log("!!! FAILED to remove pokemon from party.")
            console.log(e)
        }
    }, [])


    const api = useMemo(() => ({
        trainers,
        editTrainer,
        editTrainerSave,
        trainerSelected,
        setTrainerSelected,
        addNewTrainer,
        deleteTrainer,
        addPokemon,
        deletePokemon
    }), [trainers, editTrainer, editTrainerSave, trainerSelected, setTrainerSelected, addNewTrainer, deleteTrainer, addPokemon, deletePokemon]);
    return (
        <TrainersContext.Provider value={api}>
            {props.children}
        </TrainersContext.Provider>
    )

}

export const useTrainersContext = () => useContext(TrainersContext);