import {AllPokemon} from "../components/AllPokemon";
import {Container, FormSelect} from "react-bootstrap";
import {useState} from "react";

export function GenerationsPokemon(props) {
    const {pokemons} = props
    const [generationSelected, setGenerationSelected] = useState(5)
    return (
        <Container>
            <FormSelect className="m-2">
                <option onClick={() => setGenerationSelected(0)}>Choose a generation</option>
                <option onClick={() => setGenerationSelected(1)}>One</option>
                <option onClick={() => setGenerationSelected(2)}>Two</option>
                <option onClick={() => setGenerationSelected(3)}>Three</option>
                <option onClick={() => setGenerationSelected(4)}>Four</option>
                <option onClick={() => setGenerationSelected(5)}>Five</option>
                <option onClick={() => setGenerationSelected(6)}>Six</option>
                <option onClick={() => setGenerationSelected(7)}>Seven</option>
                <option onClick={() => setGenerationSelected(8)}>Eight</option>
            </FormSelect>
            <AllPokemon pokemons={pokemons.filter(p => p.generation === generationSelected)}/>
        </Container>
    );
}


