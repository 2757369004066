import {Alert, Container, Spinner} from "react-bootstrap";
import {Search} from "../components/Search";
import {useState} from "react";
import {PokemonData} from "../components/PokemonData";
import {useLocalStorage} from "../hooks/useLocalStorage";

export function SearchPage() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("")
    const [lastSearched, setLastSearched] = useLocalStorage("lastSearchedPokemon", null);
    const [pokemon, setPokemon] = useState(lastSearched);
    const getPokemon = async (query) => {
        if (!query) {
            setErrorMessage("Please enter a Pokémon (by name or number).")
            setError(true);
            return;
        }
        setError(false);
        setLoading(true);
        setTimeout(async () => {
            try {
                const response = await fetch(`https://pokeapi.co/api/v2/pokemon/${query}`);
                const results = await response.json();
                const secondResponse = await fetch(`https://pokeapi.co/api/v2/pokemon-species/${query}`);
                const secondResults = await secondResponse.json();
                const fileterdFlavorTextEntries = secondResults.flavor_text_entries.filter(
                    (element) => element.language.name === "en"
                );
                const flavorTextEntry = fileterdFlavorTextEntries.length > 0 ? fileterdFlavorTextEntries[0] : {};
                const mergedResults = {...results,...flavorTextEntry}
                //console.log(mergedResults)
                setPokemon(mergedResults);
                setLastSearched(mergedResults);
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
                setError(true);
                setErrorMessage("This Pokémon was not found. Try again.");
            }
        }, 1500);
    }

    return (
        <Container>
            {error ? <Alert variant="warning">
                {errorMessage}
            </Alert> : null}
            <Search getPokemon={getPokemon}/>
            {loading ? (
                <div style={{textAlign: 'center'}} className={'mt-3'}>
                    <Spinner animation="border"/>
                </div>
            ) : null}
            {!loading && pokemon ? (
                <PokemonData
                    name={pokemon.species.name}
                    sprite={pokemon.sprites.other["official-artwork"].front_default}
                    types={pokemon.types}
                    id={pokemon.id}
                    stats={pokemon.stats}
                    desc={pokemon.flavor_text}
                />
            ) : null
            }
        </Container>
    )
}