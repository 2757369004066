import {Trainers} from "../components/Trainers";
import {Button, Card, Col, Container, Form, Modal, Row} from "react-bootstrap";
import {useState} from "react";
import {v4 as uuidv4} from "uuid";
import Icon from '@mdi/react';
import {mdiPlus} from '@mdi/js';
import {useTrainersContext} from "../contexts/TrainersContext";
import {SearchForTrainer} from "../components/SearchForTrainer";


function TrainerFormEdit(props) {
    const {trainer, onClose, onSaveTrainer, onDeletePokemon} = props;
    const [selectedPokemon, setSelectedPokemon] = useState(0);
    const [trainerToEdit, setTrainerToEdit] = useState(trainer);
    const [showSearch, setShowSearch] = useState(false);

    return (
        <Modal show={!!trainerToEdit} onHide={onClose}>
            <Modal.Header closeButton>
                <div>EDIT TRAINER</div>
            </Modal.Header>
            <div className="m-3 mt-0">
                <Form>
                    <Form.Label className="mt-2 ms-1">Name:</Form.Label>
                    <Form.Control
                        value={trainerToEdit.name}
                        onChange={e => setTrainerToEdit({...trainerToEdit, name: e.target.value})}/>
                    <Form.Label className="mt-2 ms-1">City:</Form.Label>
                    <Form.Control
                        value={trainerToEdit.city}
                        onChange={e => setTrainerToEdit({...trainerToEdit, city: e.target.value})}/>
                </Form>
                <div>
                    <h6 className="mt-2 ms-1">Party: </h6>
                    <Row>
                        {trainer.party.map(p =>
                            <Col xs={4} lg={2} key={p}>
                                <Card>
                                    <img
                                        src={`https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/${p}.png`}
                                        alt={`Pokemon with id ${p}`}
                                        onClick={async () => {
                                            await setSelectedPokemon(p);
                                            console.log(selectedPokemon);
                                            onDeletePokemon(trainer, selectedPokemon);
                                        }}
                                    />
                                </Card>
                            </Col>)}
                        {(trainer.party.length < 6) && <Col xs={4} lg={2} key={uuidv4()}>
                            <Card onClick={() => setShowSearch(!showSearch)}>
                                <Icon path={mdiPlus} size={'100%'} color={'#787878'}/>
                            </Card>
                        </Col>}
                    </Row>
                    <Row>
                        {showSearch && <Col>
                            <SearchForTrainer trainer={trainer}/>
                        </Col>}
                    </Row>
                </div>
                <div className="d-flex justify-content-center p-2">
                    <Button className="m-1" size="lg" variant="danger" onClick={() => onClose()}>CANCEL</Button>
                    <Button className="m-1" size="lg" variant="success" onClick={async () => {
                        if (await onSaveTrainer(trainerToEdit)) onClose();
                    }}>SAVE</Button>
                </div>
            </div>
        </Modal>
    )
}

export function TrainerPage() {
    const {
        setTrainerSelected,
        trainerSelected,
        trainers,
        editTrainerSave,
        addNewTrainer,
        deleteTrainer,
        addPokemon,
        deletePokemon
    } = useTrainersContext();


    function editTrainer(trainer) {
        console.log(`Currently editing ${trainer.name}`)
        setTrainerSelected(trainer);
    }

    return (
        <Container>
            {trainerSelected && <TrainerFormEdit
                trainer={trainerSelected}
                onClose={() => setTrainerSelected()}
                onSaveTrainer={editTrainerSave}
                onDeletePokemon={deletePokemon}
                onAddPokemon={addPokemon}
            />}
            <Trainers trainers={trainers}
                      onEditTrainer={editTrainer}
                      onDeleteTrainer={deleteTrainer}
            ></Trainers>
            <Button onClick={() => addNewTrainer()}>Add a new trainer</Button>
        </Container>
    )

}