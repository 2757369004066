import {Button, Row} from "react-bootstrap";
import {OnePokemon} from "./OnePokemon";
import PropTypes from "prop-types";
import {useState} from "react";

export function RandomPokemon(props) {
    const {pokemons} = props;
    const [randomPokemon, setRandomPokemon] = useState(0)
    const [isClicked, setIsClicked] = useState(false)

    function getRandomPokemon() {
        return pokemons[Math.floor(Math.random() * pokemons.length)];
    }

    return (
        <>
            <Button className="m-2"
                    onClick={() => {
                        setRandomPokemon(getRandomPokemon());
                        setIsClicked(true)
                    }}>
                {isClicked ? "Give me another one" : "Give me a random starter"}
            </Button>
            <Row>
                <OnePokemon key={randomPokemon.id} pokemon={randomPokemon}/>
            </Row>
        </>
    )
}

RandomPokemon.propTypes = {
    pokemons: PropTypes.arrayOf(PropTypes.object)
}