import PropTypes from "prop-types";
import {Card, Col} from "react-bootstrap";
import {useState} from "react";

export function OnePokemon(props) {
    const {pokemon} = props;
    const [isSelected, setIsSelected] = useState(false)
    const color = isSelected ? "text-success" : ""
    if (!pokemon.name) return;
    return (
        <Col xs={12} sm={6} md={4} lg={3} xl={2}>
            <Card className={`m-2 p-2 shadow-sm text-center ${color}`} onClick={() => setIsSelected(!isSelected)}>
                <img src={`https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/${pokemon.id}.png`}
                     alt={`Picture of ${pokemon.name}`}/>
                <h5>{pokemon.name}</h5>
            </Card>
        </Col>
    )
}

OnePokemon.propTypes = {
    pokemon: PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
        generation: PropTypes.number,
        type: PropTypes.string
    })
}