import {OnePokemon} from "./OnePokemon";
import PropTypes from "prop-types";
import {Row} from "react-bootstrap";

export function AllPokemon(props) {
    const {pokemons} = props;
    return (
        <Row>
            {pokemons.map(p => <OnePokemon key={p.id} pokemon={p}/>)}
        </Row>
    )
}

AllPokemon.propTypes = {
    pokemons: PropTypes.arrayOf(PropTypes.object)
}