export const POKEMON = [
    {name: "Bulbasaur", id: 1, generation: 1, type: "Grass"},
    {name: "Charmander", id: 4, generation: 1, type: "Fire"},
    {name: "Squirtle", id: 7, generation: 1, type: "Water"},
    {name: "Chikorita", id: 152, generation: 2, type: "Grass"},
    {name: "Cyndaquil", id: 155, generation: 2, type: "Fire"},
    {name: "Totodile", id: 158, generation: 2, type: "Water"},
    {name: "Treecko", id: 252, generation: 3, type: "Grass"},
    {name: "Torchic", id: 255, generation: 3, type: "Fire"},
    {name: "Mudkip", id: 258, generation: 3, type: "Water"},
    {name: "Turtwig", id: 387, generation: 4, type: "Grass"},
    {name: "Chimchar", id: 390, generation: 4, type: "Fire"},
    {name: "Piplup", id: 393, generation: 4, type: "Water"},
    {name: "Snivy", id: 495, generation: 5, type: "Grass"},
    {name: "Tepig", id: 498, generation: 5, type: "Fire"},
    {name: "Oshawott", id: 501, generation: 5, type: "Water"},
    {name: "Chespin", id: 650, generation: 6, type: "Grass"},
    {name: "Fennekin", id: 653, generation: 6, type: "Fire"},
    {name: "Froakie", id: 656, generation: 6, type: "Water"},
    {name: "Rowlet", id: 722, generation: 7, type: "Grass"},
    {name: "Litten", id: 725, generation: 7, type: "Fire"},
    {name: "Popplio", id: 728, generation: 7, type: "Water"},
    {name: "Grookey", id: 810, generation: 8, type: "Grass"},
    {name: "Scorbunny", id: 813, generation: 8, type: "Fire"},
    {name: "Sobble", id: 816, generation: 8, type: "Water"},

];