import {Button, Col, Form, Row} from "react-bootstrap";
import {useState} from "react";

export function Search(props) {

    const [search, setSearch] = useState("");

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            event.preventDefault();
            props.getPokemon(search);
        }
    }

    return (
        <Form className="mt-2">
            <Row>
                <Col sm={10} className="my-1">
                    <Form.Control
                        onChange={(e) => setSearch(e.target.value.toLowerCase())}
                        onKeyDown={handleKeyDown}
                        placeholder="Search a Pokemon"></Form.Control>
                </Col>
                <Col sm={2} className="my-1">
                    <div className="d-grid gap-2">
                        <Button onClick={() => props.getPokemon(search)}>Search</Button>
                    </div>
                </Col>
            </Row>
        </Form>
    )
}